import type { InjectionKey } from 'vue';

import type { Source } from '~/src/classes.ts';

export const addPronounInjectionKey = Symbol() as InjectionKey<(pronoun: string) => void>;
export const changeSourceInjectionKey = Symbol() as InjectionKey<{
    approve: (source: Source) => void;
    hide: (source: Source) => void;
    remove: (source: Source) => void;
    edit: (source: Source) => void;
}>;
